export default {
  strategy: 'prefix',
  trailingSlash: 'append',
  baseUrl: process.env.BASE_URL || 'http://localhost:3000',
  locales: [
    {
      code: 'en-us',
      label: 'English',
      file: 'en_US/index.ts',
      iso: 'en',
      isCatchallLocale: false,
      flag: 'united-states.svg',
    },
    {
      code: 'de-de',
      label: 'Deutsch',
      file: 'de_DE/index.ts',
      iso: 'de',
      isCatchallLocale: false,
      flag: 'germany.svg',
    },
  ],
  defaultLocale: 'de-de',
  lazy: true,
  langDir: 'translations/',
  compilation: {
    strictMessage: false,
  },
  detectBrowserLanguage: false,
}
